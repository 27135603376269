import indexOf from 'lodash/indexOf';

import type { NonMutable } from './object';

export const append = <Item>(arg: Item) => (arr: Item[]): Item[] => arr.concat([arg]);
export const drop = <Item>(arg: Item) => (arr: Item[]): Item[] => arr
  .filter((item) => item !== arg);

export const join = <Item>(arg: Item[]) => (arr: Item[]): Item[] => arr.concat(arg);
export const remove = <Item>(arg: Item[]) => (arr: Item[]): Item[] => arr.filter((item) => !arg.includes(item));

export const insertAtIndex = <Item>(arg: Item, index: number, arr: NonMutable<Item[]>): Item[] => [
  ...arr.slice(0, index),
  arg,
  ...arr.slice(index),
];

export const joinedFilters = <Item>(
  arg: ((arg: Item) => boolean)[],
): (arg: Item) => boolean => (item) => arg.every((filter) => filter(item));

export const replaceAtIndex = <Item>(arg: Item, index: number, arr: NonMutable<Item[]>): Item[] => arr
  .map((el, i) => (i === index ? arg : el));

export const sortByOrder = <OrderItem, ArrayItem>(
  arg: ArrayItem[], order: OrderItem[], extract: (item: ArrayItem) => OrderItem,
): ArrayItem[] => {
  const copy = [...arg];
  copy.sort((itemA, itemB) => indexOf(order, extract(itemA)) - indexOf(order, extract(itemB)));
  return copy;
};

export default {};
