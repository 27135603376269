/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


  export type ScalarParsers = Partial<Readonly<{
    MonthDay: (arg: Date | null) => string | null;
		Date: (arg: Date | null) => string | null;
		LocalDate: (arg: Date | null) => string | null;
		LocalDateTime: (arg: Date | null) => string | null;
		LocalTime: (arg: Date | null) => string | null;
		DateTime: (arg: Date | null) => string | null;
		OffsetDateTime: (arg: Date | null) => string | null;
		YearMonth: (arg: Date | null) => string | null;
		YearInput: (arg: number | null) => number | null;
		ID: (arg: string | null) => string | null;
		BenefitVariantProductId: (arg: string | null) => string | null;
		InvoiceDetailsId: (arg: string | null) => string | null;
		VatTypeInput: (arg: "VatNotApplicable" | "ExemptFromVAT" | number | null) => "VatNotApplicable" | "ExemptFromVAT" | number | null;
		NfsErrorId: (arg: string | null) => string | null;
		ActivityId: (arg: string | null) => string | null;
		AlternativeBenefitsGroupId: (arg: string | null) => string | null;
		BankAccountId: (arg: string | null) => string | null;
		CompanyId: (arg: string | null) => string | null;
		ClientContractId: (arg: string | null) => string | null;
		ClientPositionId: (arg: string | null) => string | null;
		ProjectId: (arg: string | null) => string | null;
		ProjectUserId: (arg: string | null) => string | null;
		SpaceId: (arg: string | null) => string | null;
		ContractId: (arg: string | null) => string | null;
		UserId: (arg: string | null) => string | null;
		AdminId: (arg: string | null) => string | null;
		AuditEntryId: (arg: string | null) => string | null;
		BonusId: (arg: string | null) => string | null;
		OfficeId: (arg: string | null) => string | null;
		ClientId: (arg: string | null) => string | null;
		WorkTagId: (arg: string | null) => string | null;
		WorkRecordId: (arg: string | null) => string | null;
		OnCallRecordId: (arg: string | null) => string | null;
		AbsenceRecordId: (arg: string | null) => string | null;
		InvoiceRecordId: (arg: string | null) => string | null;
		ReportingPeriodId: (arg: string | null) => string | null;
		RevenueAdministrationOfficeId: (arg: number | null) => number | null;
		Cents: (arg: number | null) => number | null;
		Minutes: (arg: number | null) => number | null;
		BigDecimal: (arg: number | null) => number | null;
		Unit: (arg: Record<never, never> | null) => undefined | null;
		URL: (arg: string | null) => string | null;
		Cursor: (arg: string | null) => string | null;
		BasisPoint: (arg: number | null) => number | null;
		Currency: (arg: string | null) => string | null;
		BenefitId: (arg: string | null) => string | null;
		BenefitVariantId: (arg: string | null) => string | null;
		AssignedVariantId: (arg: string | null) => string | null;
		Json: (arg: string | null) => string | null;
		EntityVersion: (arg: number | null) => number | null;
		Days: (arg: number | null) => number | null;
		Year: (arg: number | null) => number | null;
		InvoiceNumber: (arg: string | null) => string | null;
		URI: (arg: string | null) => string | null;
		Upload: (arg: FileList[number] | null) => undefined | null;
		Iban: (arg: string | null) => string | null;
		Bic: (arg: string | null) => string | null;
		TaxNumber: (arg: string | null) => string | null;
		CountryCode: (arg: string | null) => string | null;
		PESEL: (arg: string | null) => string | null;
		EmailAddress: (arg: string | null) => string | null;
		REGON: (arg: string | null) => string | null;
		PhoneNumber: (arg: string | null) => string | null;
		RegisterId: (arg: string | null) => string | null
  }>>

  export type Path = [string, keyof ScalarParsers];

  export const scalarPaths: Record<string, Path[]> = {
    AboutProject: [["projectId","ProjectId"]],
		AccessRightsProvider: [],
		AddClientContractDrawer_Input: [["id","CompanyId"]],
		AddClientContractDrawer_Create: [["id","ClientContractId"],["promiseeId","CompanyId"],["promisorId","CompanyId"],["contractDurationFrom","LocalDate"],["contractDurationTo","LocalDate"]],
		AddCompanyDrawer_Create: [["company.address.country","CountryCode"],["company.bankAccounts[].bic","Bic"],["company.bankAccounts[].currency","Currency"],["company.countryOfRegistration","CountryCode"],["company.id","CompanyId"],["company.mailingAddress.country","CountryCode"],["company.registerId","RegisterId"],["company.regon","REGON"],["company.taxNumber","TaxNumber"]],
		AddCompanyForm_RegisterInfo: [["countryCode","CountryCode"],["taxNumber","TaxNumber"]],
		AddInternalProjectDrawer_Create: [["projectId","ProjectId"],["groupCompanyId","CompanyId"],["startDate","LocalDate"],["spaceId","SpaceId"]],
		AddPersonDrawer_Create: [["user.address.country","CountryCode"],["user.birthDate","LocalDate"],["user.citizenship","CountryCode"],["user.contactEmail","EmailAddress"],["user.email","EmailAddress"],["user.isStudentRevisionDate","LocalDate"],["user.mailingAddress.country","CountryCode"],["user.nextMedicalExaminationDate","LocalDate"],["user.nextOhsTrainingDate","LocalDate"],["user.operationalLeadId","UserId"],["user.pesel","PESEL"],["user.phoneNumber","PhoneNumber"],["user.practiceLeadId","UserId"],["user.revenueAdministrationOffice","RevenueAdministrationOfficeId"],["user.spaceId","SpaceId"],["user.workPlace.officeId","OfficeId"]],
		AddProjectDrawer_Create: [["projectId","ProjectId"],["clientContractId","ClientContractId"],["spaceId","SpaceId"],["statusHistory.dateRange.from","LocalDate"],["statusHistory.dateRange.toOpt","LocalDate"]],
		AddProjectForm_CompanyAutocomplete: [["filter.addressCountry_eq","CountryCode"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"]],
		AddProjectForm_ContractAutocomplete: [["companyId","CompanyId"]],
		AddSpaceDrawer_Create: [["spaceId","SpaceId"],["spaceOwner","UserId"]],
		AllBonusesDrawer_Delete: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"]],
		AllBonusesDrawer: [["contractId","ContractId"]],
		AnnualLeaveDetails: [["userId","UserId"]],
		AnnualLeaveEntitlement: [["contractId","ContractId"],["entityVersion","EntityVersion"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"]],
		AnnualLeaveEntitlementDrawer_SetEntitlement: [["contractId","ContractId"],["entityVersion","EntityVersion"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"]],
		AnnualLeavePreferencesDrawer_InitialData: [["contractId","ContractId"]],
		AnnualLeavePreferencesDrawer_UpdatePreferences: [["contractId","ContractId"],["entityVersion","EntityVersion"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"]],
		AnnualLeaveSummary: [["contractId","ContractId"],["year","Year"]],
		AnnualLeaveSummary_UpdateYear: [["contractId","ContractId"],["entityVersion","EntityVersion"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"]],
		AnnualLeaveSummaryDrawer: [["contractId","ContractId"],["year","Year"]],
		AssignBenefitDrawer_AllBenefits: [],
		AssignBenefitDrawer_InitialData: [["contractId","ContractId"]],
		AssignBenefitDrawer_PreviousValues: [["contractId","ContractId"],["at","OffsetDateTime"]],
		AssignBenefitDrawer_TimeBasedInput: [["contractId","ContractId"],["at","OffsetDateTime"]],
		AssignBenefitDrawer_AssignVariants: [["contractId","ContractId"],["effectiveFrom","Date"],["benefitVariants.id","AssignedVariantId"],["benefitVariants.variantId","BenefitVariantId"],["entityVersion","EntityVersion"]],
		B2BContractDraftDrawer_Input: [["userId","UserId"]],
		B2BContractDraftDrawer_Create: [["userId","UserId"],["userEntityVersion","EntityVersion"],["contractId","ContractId"],["startDate","LocalDate"],["expectedEndDate","LocalDate"],["promiseeCompanyId","CompanyId"],["promisorId","CompanyId"]],
		B2BContractDraftDrawer_Update: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"],["startDate.value","LocalDate"],["expectedEndDate.value","LocalDate"],["promiseeCompanyId.value","CompanyId"],["promisorId","CompanyId"]],
		B2BContractDraftDrawer_UpdateUser: [["userId","UserId"],["userEntityVersion","EntityVersion"],["pesel.value","PESEL"],["birthDate.value","LocalDate"]],
		B2BSalariesDetailsDrawer: [["contractId","ContractId"],["month","YearMonth"],["monthStart","LocalDate"],["monthEnd","LocalDate"]],
		B2BSalariesDetailsDrawer_UpdateTransferDeduction: [["contractId","ContractId"],["month","YearMonth"],["entityVersion","EntityVersion"],["transferDeduction.value.grossValue","Cents"]],
		B2BSalariesDetailsDrawer_UpdateInvoice: [["contractId","ContractId"],["month","YearMonth"],["invoiceDetailsId","InvoiceDetailsId"],["invoiceDetails.file.value","Upload"],["invoiceDetails.number.value","InvoiceNumber"]],
		B2BSalariesDetailsDrawer_CreateInvoice: [["contractId","ContractId"],["month","YearMonth"],["invoiceDetails.file","Upload"],["invoiceDetails.id","InvoiceDetailsId"],["invoiceDetails.number","InvoiceNumber"]],
		B2BSalariesDetailsDrawer_RemoveInvoice: [["contractId","ContractId"],["month","YearMonth"],["invoiceDetailsId","InvoiceDetailsId"]],
		B2BSalariesListItem_MarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		B2BSalariesListItem_UnmarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		B2BSalariesSummaryReportDrawer_Generate: [["yearMonth","YearMonth"]],
		B2BSalariesSummaryReportDrawer: [["yearMonth","YearMonth"]],
		BasicFinancialReportDrawer: [],
		BenefitInvoicesReportDrawer_Generate: [["yearMonth","YearMonth"]],
		BenefitInvoicesReportDrawer_GoogleDoc: [["yearMonth","YearMonth"]],
		BenefitNoteDrawer_Contract: [["contractId","ContractId"]],
		BenefitNoteDrawer_UpdateNote: [["contractId","ContractId"],["entityVersion","EntityVersion"],["assignedVariant","AssignedVariantId"]],
		BenefitsProducts: [],
		BonusDrawer_Input: [["contractId","ContractId"]],
		BonusDrawer_ManageOtherBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"]],
		BonusDrawer_ManageEmployeeReferralBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"],["referredEmployeeContractId","ContractId"]],
		BonusDrawer_ManageAppreciationBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"]],
		BonusDrawer_ManageLanguageLearningBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"],["quarterYear.year","Year"]],
		BonusDrawer_ManageTrainingPackageBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"]],
		BonusDrawer_ManageRemoteWorkEquipmentBonus: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"],["amount.gross","Cents"],["amount.net","Cents"],["applyAt","YearMonth"]],
		BonusForm_ReferredUserInfo: [["userId","UserId"]],
		BonusForm_UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		BonusForm_TrainingPackageBudget: [["contractId","ContractId"],["year","Year"]],
		BonusPreview_InitialData: [["contractId","ContractId"]],
		BusinessTripsReportDrawer: [],
		ClientContractDetails: [["clientContractId","ClientContractId"]],
		ClientContractDetails_CancelActivities: [["clientContractId","ClientContractId"],["entityVersion","EntityVersion"],["date","LocalDate"]],
		ClientContractForm_Promisor: [["id","CompanyId"]],
		ClientContracts: [["companyId","CompanyId"]],
		ClientInvoicingReportDrawer_Generate: [["clientContractId","ClientContractId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		ClientInvoicingReportDrawer_GoogleDoc: [["clientContractId","ClientContractId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		ClientInvoicingReportForm_ReportingPeriods: [["clientContractId","ClientContractId"]],
		CompanyDetailsDrawer_InitialData: [["companyId","CompanyId"]],
		CompanyDetailsDrawer_RegisterDetails: [["id","CompanyId"],["entityVersion","EntityVersion"],["address.country","CountryCode"],["mailingAddress.value.country","CountryCode"]],
		CompanyDetailsDrawer_VatTax: [["companyId","CompanyId"],["entityVersion","EntityVersion"],["euVatType.applyDate","Date"],["isPolishVat.applyDate","Date"],["isOtherVatType.applyDate","Date"]],
		CompanyEditBankAccountsForm_Update: [["companyId","CompanyId"],["entityVersion","EntityVersion"],["bankAccount.bic","Bic"],["bankAccount.currency","Currency"]],
		CompanyEditBankAccountsForm_Delete: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		CompanyEditBankAccountsForm_CheckWhiteList: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		CompanyEditBankAccountsForm_RefreshStatuses: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		CompanyRegisterDetailsForm_RegisterData: [["countryCode","CountryCode"],["taxNumber","TaxNumber"]],
		CompanyTypeDrawer_InitialData: [["companyId","CompanyId"]],
		CompanyTypeDrawer_Update: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		CompanyVatForm_VerifyPolishVats: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		CompanyVatForm_VerifyEuVat: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		ContractAnnouncements_ExtendEndDateEmployment: [["contractId","ContractId"],["entityVersion","EntityVersion"],["expectedEndDate.value","LocalDate"]],
		ContractAnnouncements_ExtendEndDateB2B: [["contractId","ContractId"],["entityVersion","EntityVersion"],["expectedEndDate.value","LocalDate"]],
		ContractBankAccount: [["contractId","ContractId"]],
		ContractBankAccountDrawer_ContractInputPromisor: [["contractId","ContractId"]],
		ContractBankAccountDrawer_ContractInputPromisee: [["contractId","ContractId"]],
		ContractBankAccountDrawer_DraftInputPromisor: [["userId","UserId"]],
		ContractBankAccountDrawer_DraftInputPromisee: [["userId","UserId"]],
		ContractBankAccountDrawer_UpdateB2BDraftPromisor: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"]],
		ContractBankAccountDrawer_UpdateEmploymentDraftPromisor: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"]],
		ContractBankAccountDrawer_UpdateB2BDraftPromisee: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"]],
		ContractBankAccountDrawer_UpdateB2BContractPromisor: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractBankAccountDrawer_UpdateEmploymentContractPromisor: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractBankAccountDrawer_UpdateB2BContractPromisee: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractBankAccountForm_RefreshWhiteList: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		ContractBankAccountSection_RefreshWhiteList: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		ContractBenefits_DiscardPlannedChange: [["contractId","ContractId"],["entityVersion","EntityVersion"],["at","Date"]],
		ContractBenefits: [["contractId","ContractId"]],
		ContractBonuses_Delete: [["contractId","ContractId"],["entityVersion","EntityVersion"],["bonusId","BonusId"]],
		ContractBonuses: [["contractId","ContractId"]],
		ContractDetails: [["contractId","ContractId"]],
		ContractDetailsDrawer_InitialData: [["contractId","ContractId"],["year","Year"]],
		ContractDetailsDrawer_UpdateEmployment: [["contractId","ContractId"],["entityVersion","EntityVersion"],["position.applyDate","Date"],["scopeOfWork.applyDate","Date"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"],["paidLeaveEntitlement.value","Days"]],
		ContractDetailsDrawer_UpdateB2B: [["contractId","ContractId"],["entityVersion","EntityVersion"],["position.applyDate","Date"],["scopeOfWork.applyDate","Date"]],
		ContractDetailsSection: [["contractId","ContractId"]],
		ContractDraftActionButton_Validate: [["userId","UserId"]],
		ContractDraftDetails: [["userId","UserId"]],
		ContractListFilters_Promisors: [["after","Cursor"]],
		ContractNoteDrawer_DraftInput: [["userId","UserId"]],
		ContractNoteDrawer_ContractInput: [["contractId","ContractId"]],
		ContractNoteDrawer_UpdateB2BContract: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractNoteDrawer_UpdateEmploymentContract: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractNoteDrawer_UpdateSubcontract: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		ContractNoteDrawer_UpdateB2BDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"]],
		ContractNoteDrawer_UpdateEmploymentDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"]],
		ContractNoteDrawer_UpdateSubcontractDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"]],
		ContractPaidLeave: [["contractId","ContractId"]],
		ContractPaymentDetails: [["contractId","ContractId"]],
		ContractPaymentDetails_DiscardActivities: [["contractId","ContractId"],["entityVersion","EntityVersion"],["activityIds","ActivityId"]],
		ContractsAndBenefits: [["userId","UserId"]],
		ContractsAndBenefits_DeleteDraft: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"]],
		ContractsAndBenefits_ConcludeDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"],["date","LocalDate"]],
		ContractsAndBenefits_ConcludeSubcontractDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"],["date","LocalDate"]],
		ContractsAndBenefits_DraftPreview: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"],["date","LocalDate"]],
		ContractsAndBenefits_RegenerateFile: [["contractId","ContractId"],["entityVersion","EntityVersion"]],
		CostSummariesDrawer: [["filter.conclusionDate_gt","LocalDate"],["filter.conclusionDate_lt","LocalDate"],["filter.contractId_oneOf[]","ContractId"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.expectedEndDate_gt","LocalDate"],["filter.expectedEndDate_lt","LocalDate"],["filter.promiseeId_notIn[]","UserId"],["filter.promisorId_eq","CompanyId"],["filter.startDate_gt","LocalDate"],["filter.startDate_lt","LocalDate"],["filter.terminationDate_gt","LocalDate"],["filter.terminationDate_lt","LocalDate"],["range.from","YearMonth"],["range.to","YearMonth"]],
		CreateBenefitDrawer_Create: [["id","BenefitId"],["alternativeBenefitsGroupId","AlternativeBenefitsGroupId"],["startDate","LocalDate"],["endDate","LocalDate"]],
		CreateSubBenefitDrawer_Create: [["id","BenefitId"],["parentId","BenefitId"],["parentEntityVersion","EntityVersion"],["alternativeBenefitsGroupId","AlternativeBenefitsGroupId"],["startDate","LocalDate"],["endDate","LocalDate"]],
		CreateVariantDrawer_InitialData: [["id","BenefitId"]],
		CreateVariantDrawer_Create: [["id","BenefitVariantId"],["parentId","BenefitId"],["parentEntityVersion","EntityVersion"],["productId","BenefitVariantProductId"],["startDate","LocalDate"],["endDate","LocalDate"],["promiseeRate.net","Cents"],["promiseeRate.vat","VatTypeInput"],["promisorRate.net","Cents"],["promisorRate.vat","VatTypeInput"]],
		DirectReporting_Discard: [["activityIds","ActivityId"],["userId","UserId"],["entityVersion","EntityVersion"]],
		DirectReportingDrawer_InitialData: [["userId","UserId"]],
		DirectReportingDrawer_PreviousValues: [["userId","UserId"],["at","OffsetDateTime"]],
		DirectReportingDrawer_TimeBasedInput: [["userId","UserId"],["at","OffsetDateTime"]],
		DirectReportingDrawer_UpdateUser: [["userId","UserId"],["entityVersion","EntityVersion"],["space.applyDate","Date"],["space.value","SpaceId"],["primaryProjectId.applyDate","Date"],["primaryProjectId.value.value","UserId"],["practiceLead.applyDate","Date"],["practiceLead.value.value","UserId"],["operationalLead.applyDate","Date"],["operationalLead.value.value","UserId"],["role.applyDate","Date"]],
		DirectReportingDrawer_PartialUpdateUser: [["userId","UserId"],["entityVersion","EntityVersion"],["space.applyDate","Date"],["space.value","SpaceId"],["primaryProjectId.applyDate","Date"],["primaryProjectId.value.value","UserId"],["practiceLead.applyDate","Date"],["practiceLead.value.value","UserId"],["operationalLead.applyDate","Date"],["operationalLead.value.value","UserId"]],
		DirectReportingDrawer_UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		EditBenefitDrawer_Update: [["id","BenefitId"],["entityVersion","EntityVersion"]],
		EditBenefitDrawer_Terminate: [["id","BenefitId"],["entityVersion","EntityVersion"],["endDate","LocalDate"]],
		EditBenefitDrawer_BenefitInitialData: [["id","BenefitId"]],
		EditBenefitDrawer_SubBenefitInitialData: [["id","BenefitId"]],
		EditBenefitVariantPriceDrawer_Input: [["benefitVariantId","BenefitVariantId"]],
		EditBenefitVariantPriceDrawer_PreviousValue: [["benefitVariantId","BenefitVariantId"],["at","OffsetDateTime"]],
		EditBenefitVariantPriceDrawer_TimeBasedInput: [["benefitVariantId","BenefitVariantId"],["at","OffsetDateTime"]],
		EditBenefitVariantPriceDrawer_Update: [["benefitVariantId","BenefitVariantId"],["entityVersion","EntityVersion"],["promiseeRate.applyDate","Date"],["promiseeRate.value.value.net","Cents"],["promiseeRate.value.value.vat","VatTypeInput"],["promisorRate.applyDate","Date"],["promisorRate.value.value.net","Cents"],["promisorRate.value.value.vat","VatTypeInput"]],
		EditClientContractDrawer_Input: [["id","ClientContractId"]],
		EditClientContractDrawer_Update: [["id","ClientContractId"],["entityVersion","EntityVersion"],["contractDurationFrom","LocalDate"],["contractDurationTo.value","LocalDate"]],
		EditProfileBasicInfoForm_OfficeAutocomplete: [],
		EditProfileDetailsDrawer_Input: [["userId","UserId"]],
		EditProfileDetailsDrawer_BasicInfo_Update: [["userId","UserId"],["entityVersion","EntityVersion"],["space.applyDate","Date"],["space.value","SpaceId"],["contactEmail.value","EmailAddress"],["phoneNumber.value","PhoneNumber"],["progressionLevel.applyDate","Date"],["workPlace.value.officeId","OfficeId"],["personalDataProcessingAuthorization.value","URI"],["documentsDirectory","URI"]],
		EditProfileDetailsDrawer_Address_Update: [["userId","UserId"],["entityVersion","EntityVersion"],["address.value.country","CountryCode"],["mailingAddress.value.country","CountryCode"]],
		EditProfileDetailsDrawer_AdditionalData_Update: [["userId","UserId"],["entityVersion","EntityVersion"],["pesel.value","PESEL"],["citizenship.value","CountryCode"],["birthDate.value","LocalDate"],["nextMedicalExaminationDate.value","LocalDate"],["isStudentRevisionDate.value","LocalDate"],["revenueAdministrationOffice.value","RevenueAdministrationOfficeId"]],
		EditProjectDrawer_Input: [["projectId","ProjectId"]],
		EditProjectDrawer_Update: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["spaceId.value","SpaceId"],["statusHistory.dateRange.from","LocalDate"],["statusHistory.dateRange.toOpt","LocalDate"]],
		EditSpaceDrawer_InitialData: [["spaceId","SpaceId"]],
		EditSpaceDrawer_Update: [["spaceId","SpaceId"],["entityVersion","EntityVersion"],["owner","UserId"]],
		EditVariantDrawer_Update: [["id","BenefitVariantId"],["entityVersion","EntityVersion"],["productId","BenefitVariantProductId"],["promiseeRate.applyDate","Date"],["promiseeRate.value.value.net","Cents"],["promiseeRate.value.value.vat","VatTypeInput"],["promisorRate.applyDate","Date"],["promisorRate.value.value.net","Cents"],["promisorRate.value.value.vat","VatTypeInput"]],
		EditVariantDrawer_Input: [["id","BenefitVariantId"]],
		EditVariantDrawer_TimeBasedInput: [["id","BenefitVariantId"],["at","OffsetDateTime"]],
		EditVariantDrawer_PreviousValue: [["id","BenefitVariantId"],["at","OffsetDateTime"]],
		EmploymentBankAccountDrawer_ContractInput: [["contractId","ContractId"]],
		EmploymentBankAccountDrawer_DraftInput: [["userId","UserId"]],
		EmploymentBankAccountDrawer_UpdateEmploymentDraft: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"],["promiseeTransferBankAccount.value.bic","Bic"],["promiseeTransferBankAccount.value.currency","Currency"]],
		EmploymentBankAccountDrawer_UpdateContract: [["contractId","ContractId"],["entityVersion","EntityVersion"],["promiseeTransferBankAccount.bic","Bic"],["promiseeTransferBankAccount.currency","Currency"]],
		EmploymentContractDraftDrawer_Input: [["userId","UserId"]],
		EmploymentContractDraftDrawer_Create: [["userId","UserId"],["userEntityVersion","EntityVersion"],["contractId","ContractId"],["promisorId","CompanyId"],["startDate","LocalDate"],["expectedEndDate","LocalDate"],["paidLeaveEntitlement","Days"]],
		EmploymentContractDraftDrawer_Update: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"],["startDate.value","LocalDate"],["expectedEndDate.value","LocalDate"],["paidLeaveEntitlement.value","Days"],["promisorId","CompanyId"]],
		EmploymentContractDraftDrawer_UpdateUser: [["userId","UserId"],["userEntityVersion","EntityVersion"],["pesel.value","PESEL"],["birthDate.value","LocalDate"],["address.value.country","CountryCode"],["mailingAddress.value.country","CountryCode"],["isStudentRevisionDate.value","LocalDate"],["nextMedicalExaminationDate.value","LocalDate"]],
		EmploymentSalariesListItem_MarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		EmploymentSalariesListItem_UnmarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		EmploymentSalaryDetailsDrawer: [["contractId","ContractId"],["month","YearMonth"],["monthStart","LocalDate"],["monthEnd","LocalDate"]],
		EmploymentSalaryDetailsDrawer_UpdateTransferDeduction: [["contractId","ContractId"],["month","YearMonth"],["entityVersion","EntityVersion"],["transferDeduction.value.grossValue","Cents"]],
		EmploymentSalaryDetailsDrawer_UpdateAccountingData: [["contractId","ContractId"],["month","YearMonth"],["entityVersion","EntityVersion"],["accountingData.value.maternityLeaveAllowance","Cents"],["accountingData.value.overtimePayout","Cents"],["accountingData.value.ppkAmount","Cents"],["accountingData.value.taxPrepayment","Cents"],["accountingData.value.taxRefund","Cents"],["accountingData.value.transferAmount","Cents"],["accountingData.value.zusContribution","Cents"]],
		PaymentDetailsDrawer_DraftInput: [["userId","UserId"]],
		PaymentDetailsDrawer_ContractInput: [["contractId","ContractId"]],
		PaymentDetailsDrawer_PreviousValues: [["contractId","ContractId"],["at","OffsetDateTime"]],
		PaymentDetailsDrawer_TimebasedContractInput: [["contractId","ContractId"],["at","OffsetDateTime"]],
		PaymentDetailsDrawer_UpdateEmploymentDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"],["rate.value.net","Cents"],["overtimeRate.value","Cents"],["onCall.value.holiday","Cents"],["onCall.value.night","Cents"],["onCall.value.weekend","Cents"],["ppk.value","BasisPoint"],["copyrightCosts.value","BasisPoint"],["workingTime.value.friday","Minutes"],["workingTime.value.monday","Minutes"],["workingTime.value.saturday","Minutes"],["workingTime.value.thursday","Minutes"],["workingTime.value.tuesday","Minutes"],["workingTime.value.wednesday","Minutes"],["currency.value","Currency"]],
		PaymentDetailsDrawer_UpdateB2BDraft: [["userId","UserId"],["entityVersion","EntityVersion"],["userEntityVersion","EntityVersion"],["rate.value.net","Cents"],["overtimeRate.value","Cents"],["onCall.value.holiday","Cents"],["onCall.value.night","Cents"],["onCall.value.weekend","Cents"],["vat.value","VatTypeInput"],["currency.value","Currency"]],
		PaymentDetailsDrawer_UpdateB2BContract: [["contractId","ContractId"],["entityVersion","EntityVersion"],["rateAmount.applyDate","Date"],["rateAmount.value","Cents"],["overtimeRate.applyDate","Date"],["overtimeRate.value.value","Cents"],["onCall.applyDate","Date"],["onCall.value.value.holiday","Cents"],["onCall.value.value.night","Cents"],["onCall.value.value.weekend","Cents"],["currency.applyDate","Date"],["currency.value","Currency"],["vat.applyDate","Date"],["vat.value","VatTypeInput"],["separatePaymentForBenefits.applyDate","Date"]],
		PaymentDetailsDrawer_UpdateEmploymentContract: [["contractId","ContractId"],["entityVersion","EntityVersion"],["rateAmount.applyDate","Date"],["rateAmount.value","Cents"],["overtimeRate.applyDate","Date"],["overtimeRate.value.value","Cents"],["onCall.applyDate","Date"],["onCall.value.value.holiday","Cents"],["onCall.value.value.night","Cents"],["onCall.value.value.weekend","Cents"],["ppk.applyDate","Date"],["ppk.value.value","BasisPoint"],["copyrightCosts.applyDate","Date"],["copyrightCosts.value.value","BasisPoint"],["workingTime.applyDate","Date"],["workingTime.value.friday","Minutes"],["workingTime.value.monday","Minutes"],["workingTime.value.saturday","Minutes"],["workingTime.value.thursday","Minutes"],["workingTime.value.tuesday","Minutes"],["workingTime.value.wednesday","Minutes"],["taxDeductibleCosts.applyDate","Date"],["applyRemoteWorkReimbursement.applyDate","Date"],["currency.applyDate","Date"],["currency.value","Currency"]],
		PeopleListFilters_Offices: [],
		PeopleListFilters_Spaces: [["after","Cursor"]],
		PeopleListFilters_Projects: [["after","Cursor"]],
		PeopleListReportDrawer_Main: [],
		PeopleListReportDrawer_Limited: [],
		PeopleListReportDrawer_Regenerate: [],
		Profile_User: [["userId","UserId"]],
		ProgressionDetails: [["contractId","ContractId"],["userId","UserId"]],
		ProjectBillablesReportDrawer_Generate: [["projectId","ProjectId"]],
		ProjectBillablesReportDrawer_GoogleDoc: [["projectId","ProjectId"]],
		ProjectLeaderForm_UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		ProjectLeaderForm_AdditionalInfo: [["userId","UserId"]],
		ProjectMemberForm_UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		ProjectMemberForm_UserDetails: [["userId","UserId"]],
		ProjectMembers: [["projectId","ProjectId"]],
		ProjectMembersDrawer_Members: [["projectId","ProjectId"]],
		ProjectMembersDrawer_UpdateAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["state.periods[].clientPositionId","ClientPositionId"],["state.periods[].from","LocalDate"],["state.periods[].subcontractPositionId","ClientPositionId"],["state.periods[].to","LocalDate"],["state.userId","UserId"]],
		ProjectMembersDrawer_UpdateInternalAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["state.periods[].from","LocalDate"],["state.periods[].subcontractPositionId","ClientPositionId"],["state.periods[].to","LocalDate"],["state.userId","UserId"]],
		ProjectMembersDrawer_UpdateLeadAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["periods.from","LocalDate"],["periods.leadId","UserId"],["periods.to","LocalDate"]],
		ProjectMembersDrawer_RemoveAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["userId","UserId"]],
		ProjectsAndActivities: [["userId","UserId"]],
		ProjectListFilters_Clients: [["after","Cursor"]],
		RateCardDrawer_Input: [["id","ClientContractId"]],
		RateCardDrawer_PreviousValues: [["id","ClientContractId"],["at","OffsetDateTime"]],
		RateCardDrawer_TimeBasedInput: [["id","ClientContractId"],["at","OffsetDateTime"]],
		RateCardDrawer_Update: [["at","Date"],["id","ClientContractId"],["entityVersion","EntityVersion"],["vat","BasisPoint"],["currency","Currency"],["positions.currency","Currency"],["positions.id","ClientPositionId"],["positions.onCallRates.holiday","Cents"],["positions.onCallRates.night","Cents"],["positions.onCallRates.weekend","Cents"],["positions.overtimeNet","Cents"],["positions.rateNet","Cents"]],
		SalariesControlls_TransfersFile: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		SalariesControlls_MarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		SalariesControlls_UnmarkAsDone: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		SalariesControlls_NonB2BAccountingFile: [["yearMonth","YearMonth"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"]],
		SalariesListItem_TimeReportValidations: [["date","YearMonth"],["contractId","ContractId"]],
		SalariesUserSection_UpdateFlagAndNote: [["contractId","ContractId"],["month","YearMonth"],["entityVersion","EntityVersion"]],
		SalaryTransferDetails_RefreshWhiteList: [["companyId","CompanyId"],["entityVersion","EntityVersion"]],
		SalaryTransferDetails_MarkAsDone: [["yearMonth","YearMonth"],["contractId","ContractId"]],
		SalaryTransferDetails_UnmarkAsDone: [["yearMonth","YearMonth"],["contractId","ContractId"]],
		SalaryTransferDetails_TransfersFile: [["yearMonth","YearMonth"],["contractId","ContractId"]],
		SaveAsReadyModal_InitialData: [["userId","UserId"]],
		SetFutureEntitlementDrawer_InitialData: [["contractId","ContractId"]],
		SetFutureEntitlementDrawer_SetEntitlement: [["contractId","ContractId"],["entityVersion","EntityVersion"],["annualLeaveEntitlement.history[].entitlement","Days"],["annualLeaveEntitlement.history[].from","LocalDate"],["annualLeaveEntitlement.history[].to","LocalDate"]],
		SetRateAndProgressionDrawer_ContractInput: [["contractId","ContractId"],["userId","UserId"]],
		SetRateAndProgressionDrawer_PreviousValues: [["contractId","ContractId"],["at","OffsetDateTime"],["userId","UserId"]],
		SetRateAndProgressionDrawer_TimebasedContractInput: [["contractId","ContractId"],["at","OffsetDateTime"],["userId","UserId"]],
		SetRateAndProgressionDrawer_UpdateUser: [["userEntityVersion","EntityVersion"],["userId","UserId"],["progressionLevel.applyDate","Date"]],
		SetRateAndProgressionDrawer_UpdateB2BContract: [["contractId","ContractId"],["entityVersion","EntityVersion"],["rateAmount.applyDate","Date"],["rateAmount.value","Cents"],["currency.applyDate","Date"],["currency.value","Currency"],["vat.applyDate","Date"],["vat.value","VatTypeInput"]],
		SetRateAndProgressionDrawer_UpdateEmploymentContract: [["contractId","ContractId"],["entityVersion","EntityVersion"],["rateAmount.applyDate","Date"],["rateAmount.value","Cents"],["currency.applyDate","Date"],["currency.value","Currency"]],
		SpaceAndPositionSection_DiscardActivities: [["userId","UserId"],["entityVersion","EntityVersion"],["activityIds","ActivityId"]],
		SpaceForm_UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		SubcontractDetails_Contract: [["contractId","ContractId"]],
		SubcontractDetailsSections_AssignedProjects: [["userId","UserId"]],
		SubcontractDraftDetails: [["userId","UserId"]],
		SubcontractDraftDrawer_Input: [["userId","UserId"]],
		SubcontractDraftDrawer_Create: [["userId","UserId"],["userEntityVersion","EntityVersion"],["contractId","ContractId"],["startDate","LocalDate"],["expectedEndDate","LocalDate"],["clientContractId","ClientContractId"]],
		SubcontractDraftDrawer_Update: [["userId","UserId"],["userEntityVersion","EntityVersion"],["entityVersion","EntityVersion"],["startDate.value","LocalDate"],["expectedEndDate.value","LocalDate"]],
		SubcontractDraftForm_ClientContracts: [],
		TerminateContractModal: [["contractId","ContractId"],["entityVersion","EntityVersion"],["date","LocalDate"]],
		TerminateContractModal_Info: [["contractId","ContractId"]],
		TimeReportsReportDrawer_GenerateForProject: [["projectId","ProjectId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		TimeReportsReportDrawer_GoogleDocForProject: [["projectId","ProjectId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		TimeReportsReportDrawer_GenerateForClient: [["clientContractId","ClientContractId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		TimeReportsReportDrawer_GoogleDocForClient: [["clientContractId","ClientContractId"],["period.from","LocalDate"],["period.to","LocalDate"]],
		TimeReportsReportForm_CompanyAutocomplete: [],
		TimeReportsReportForm_ClientInfo: [["companyId","CompanyId"]],
		TimeReportsReportForm_Periods: [["clientContractId","ClientContractId"]],
		TransfersSummaryModal: [["yearMonth","YearMonth"],["contractIds_in","ContractId"],["contractIds_notIn","ContractId"]],
		UopHolidaysReportDrawer_Generate: [["year","Year"]],
		UopHolidaysReportDrawer_GoogleDoc: [["year","Year"]],
		UserDocuments: [["userId","UserId"]],
		UserDocuments_UpdateUser: [["userId","UserId"],["entityVersion","EntityVersion"],["documentsDirectory","URI"]],
		UserProgression_Progression: [["userId","UserId"]],
		UserProjectForm_ProjectInfo: [["projectId","ProjectId"]],
		UserProjectsDrawer_InitialData: [["userId","UserId"]],
		UserProjectDrawer_UpdateAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["state.periods[].clientPositionId","ClientPositionId"],["state.periods[].from","LocalDate"],["state.periods[].subcontractPositionId","ClientPositionId"],["state.periods[].to","LocalDate"],["state.userId","UserId"]],
		UserProjectDrawer_UpdateInternalAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["state.periods[].from","LocalDate"],["state.periods[].subcontractPositionId","ClientPositionId"],["state.periods[].to","LocalDate"],["state.userId","UserId"]],
		UserProjectsDrawer_RemoveAssignment: [["projectId","ProjectId"],["entityVersion","EntityVersion"],["userId","UserId"]],
		UserReminderDatesDrawer: [["userId","UserId"]],
		UserReminderDatesDrawer_Update: [["userId","UserId"],["entityVersion","EntityVersion"],["isStudentRevisionDate.value","LocalDate"],["nextMedicalExaminationDate.value","LocalDate"],["nextOhsTrainingDate.value","LocalDate"]],
		UserTimeReportDetails_GenerateRaport: [["month","YearMonth"],["contractId","ContractId"]],
		UserTimeReports_Contracts: [["userId","UserId"]],
		YearlyCostSummaryDrawer_Input: [["userId","UserId"]],
		YearlyCostSummaryDrawer_LoadReport: [["contractId","ContractId"],["range.from","YearMonth"],["range.to","YearMonth"]],
		Companies: [["after","Cursor"],["filter.addressCountry_eq","CountryCode"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"]],
		CompanyDetails: [["companyId","CompanyId"]],
		Contracts: [["after","Cursor"],["filter.conclusionDate_gt","LocalDate"],["filter.conclusionDate_lt","LocalDate"],["filter.contractId_oneOf[]","ContractId"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.expectedEndDate_gt","LocalDate"],["filter.expectedEndDate_lt","LocalDate"],["filter.promiseeId_notIn[]","UserId"],["filter.promisorId_eq","CompanyId"],["filter.startDate_gt","LocalDate"],["filter.startDate_lt","LocalDate"],["filter.terminationDate_gt","LocalDate"],["filter.terminationDate_lt","LocalDate"]],
		Contracts_Drafts: [],
		People: [["after","Cursor"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		PersonDetails: [["userId","UserId"]],
		PersonDetails_Tr: [["contractId","ContractId"]],
		ProjectDetails: [["projectId","ProjectId"]],
		Projects: [["after","Cursor"],["filter.clientContractId_eq","ClientContractId"],["filter.clientId_eq","CompanyId"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.spaceId_eq","SpaceId"]],
		ReportsReminders: [["after","Cursor"]],
		Salaries: [["year","Year"]],
		SalariesDetailsB2B: [["after","Cursor"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"],["yearMonth","YearMonth"]],
		SalariesDetailsEmployment: [["after","Cursor"],["filter.contractIds_in[]","ContractId"],["filter.contractIds_notIn[]","ContractId"],["yearMonth","YearMonth"]],
		SpaceDetails: [["spaceId","SpaceId"],["after","Cursor"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		Spaces: [["after","Cursor"]],
		ClientContractAutocomplete: [["after","Cursor"]],
		CompanyAutocomplete: [["filter.addressCountry_eq","CountryCode"],["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"]],
		GroupCompanyBankAccounts: [["after","Cursor"]],
		ProjectAutocomplete: [],
		RevenueAdministrationOfficeAutocomplete: [],
		SpacesAutocomplete: [],
		UserAutocomplete: [["filter.createdAt_gt","OffsetDateTime"],["filter.createdAt_lt","OffsetDateTime"],["filter.lead_oneOf[]","UserId"],["filter.office_oneOf[]","OfficeId"],["filter.operationalLead_oneOf[]","UserId"],["filter.practiceLead_oneOf[]","UserId"],["filter.project_oneOf[]","ProjectId"],["filter.space_oneOf[]","SpaceId"],["filter.userId_oneOf[]","UserId"]],
		Drawer: [["id","ID"]],
		RootDrawer: [],
		TopDrawer: [],
		AdminBenefitSections_AssignedBenefitVariants: [["contractId","ContractId"]],
		Admin_DirectReportingStructure: [["userId","UserId"]],
		Admin_InvoicesDrawer_UpdateInvoice: [["contractId","ContractId"],["yearMonth","YearMonth"],["invoiceDetailsId","InvoiceDetailsId"],["invoiceDetails.file.value","Upload"],["invoiceDetails.number.value","InvoiceNumber"]],
		Admin_InvoicesDrawer_CreateInvoice: [["contractId","ContractId"],["yearMonth","YearMonth"],["invoiceDetails.file","Upload"],["invoiceDetails.id","InvoiceDetailsId"],["invoiceDetails.number","InvoiceNumber"]],
		Admin_InvoicesDrawer_RemoveInvoice: [["contractId","ContractId"],["yearMonth","YearMonth"],["invoiceDetailsId","InvoiceDetailsId"]],
		InvoicesDrawer_Invoices: [["contractId","ContractId"],["from","YearMonth"],["to","YearMonth"]],
		Admin_ProjectsColorsProvider_AllProjects: [["userId","UserId"]],
		Admin_TimeReportBlockedMessage_SpaceOwner: [["userId","UserId"]],
		TimeReportBulkEditToolbar_DeleteSelected: [["workRecordIds","WorkRecordId"],["onCallRecordIds","OnCallRecordId"],["absenceRecordIds","AbsenceRecordId"]],
		Admin_TimeReportDashboardProvider_ContractInfo: [["contractId","ContractId"]],
		Admin_TimeReportDashboardProvider: [["dashboardFrom","LocalDate"],["dashboardTo","LocalDate"],["projectsFrom","OffsetDateTime"],["projectsTo","OffsetDateTime"],["contractId","ContractId"]],
		TimeReportDashboardProvider_Validations: [["contractId","ContractId"],["date","YearMonth"]],
		Admin_TimeReportEntryModal_Limits: [["year","Year"],["from","LocalDate"],["to","LocalDate"],["userId","UserId"],["contractId","ContractId"]],
		TimeReportEntryModal_CreateWorkRecord: [["workRecords.contractId","ContractId"],["workRecords.date","LocalDate"],["workRecords.duration","Minutes"],["workRecords.id","WorkRecordId"],["workRecords.projectId","ProjectId"],["workRecords.workTagId","WorkTagId"],["workRecords.workTime.break","Minutes"],["workRecords.workTime.timeFrom","LocalTime"],["workRecords.workTime.timeTo","LocalTime"]],
		TimeReportEntryModal_UpdateWorkRecord: [["workRecordId","WorkRecordId"],["projectId.value","ProjectId"],["workTagId","WorkTagId"],["date","LocalDate"],["duration.value","Minutes"],["workTime.value.break","Minutes"],["workTime.value.timeFrom","LocalTime"],["workTime.value.timeTo","LocalTime"]],
		TimeReportEntryModal_DeleteWorkRecord: [["workRecordId","WorkRecordId"]],
		TimeReportEntryModal_CreateOnCallRecord: [["onCallRecords.contractId","ContractId"],["onCallRecords.date","LocalDate"],["onCallRecords.id","OnCallRecordId"],["onCallRecords.projectId","ProjectId"]],
		TimeReportEntryModal_UpdateOnCallRecord: [["onCallRecordId","OnCallRecordId"],["projectId","ProjectId"],["date","LocalDate"]],
		TimeReportEntryModal_DeleteOnCallRecord: [["onCallRecordId","OnCallRecordId"]],
		TimeReportEntryModal_CreateAbsenceRecord: [["absenceRecords.contractId","ContractId"],["absenceRecords.customDurationPerDay","Minutes"],["absenceRecords.from","LocalDate"],["absenceRecords.id","AbsenceRecordId"],["absenceRecords.to","LocalDate"]],
		TimeReportEntryModal_UpdateAbsenceRecord: [["id","AbsenceRecordId"],["dateFrom","LocalDate"],["dateTo","LocalDate"],["customDurationPerDay.value","Minutes"]],
		TimeReportEntryModal_DeleteAbsenceRecord: [["absenceRecordId","AbsenceRecordId"]],
		Admin_TimeReportFinances: [["contractId","ContractId"],["from","LocalDate"],["to","LocalDate"],["month","YearMonth"]],
		Admin_TimeReportLeaveAndTraining: [["contractId","ContractId"],["userId","UserId"],["year","Year"],["from","LocalDate"],["to","LocalDate"]],
		Admin_TimeReportOnCallDetailsModal: [],
		Admin_TimeReportStats: [["from","LocalDate"],["to","LocalDate"],["year","Year"],["contractId","ContractId"]],
		TimeReport_WorkTimeInsights: [["from","LocalDate"],["to","LocalDate"],["contractId","ContractId"]],
		Admin_PreviousMonthPaymentStatus: [["contractId","ContractId"],["date","YearMonth"]]
  }
  