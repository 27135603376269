import type { ReactElement } from 'react';

import { DateTime } from 'luxon';

import { CheckCircle, XCircle, Question } from 'phosphor-react';

import {
  AbsenceType, AbsenceTypeInput, BonusType, ContractType,
} from '../schema/admin';
import type { AbsenceType as AdminAbsenceType } from '../schema/admin';
import type { AbsenceTypeInput as UserAbsenceType, ReportedLeaveDays } from '../schema/user';
import { CalendarDayType } from '../schema/user';
import { compareTimeUnits, getDateTime } from '../services/date';
import type { TranslatingFn } from '../services/i18n';
import type { Nullable } from '../services/object';
import palette from '../theme/palette';

export const invoiceDates = {
  lockDate: (date: Date | DateTime): Date => getDateTime(date)
    .plus({ month: 1 })
    .set({ day: 10 })
    .endOf('day')
    .toJSDate(),
  unlockDate: (date: Date | DateTime): Date => getDateTime(date)
    .startOf('month')
    .toJSDate(),
};

export const spaceOwnerEmoji = '👨';

export const overtimeEmoji = '⏱';
export const onCallEmojis = {
  onCall: '📞',
  day: '☀️',
  night: '🦉',
};

export const annualLeaveEmoji = '🌴';

export const incomeEmoji = {
  base: '💰',
  overtime: '⏱',
  onCall: '📞',
  bonuses: '🍭',
  refundedBenefits: '🎁',
  notRefundedBenefits: '👎',
  transferAmount: '💰',
  taxPrepayment: '💵',
  zusContribution: '🏥',
  ppkAmount: '🗂️',
  transferDeduction: '🗃',
  totalAccountingData: '💸',
  remoteWork: '🏠',
  referralBonus: '👬 ',
  appreciationBonus: '🥇',
  languageBonus: '🇬🇧',
  totalBonuses: '💸',
  otherBonus: '💵 ',
  trainingPackageBonus: '🦉',
  remoteWorkEquipmentBonus: '🖥️',
};

export const incomeBenefitColors = {
  refunded: palette.support.success[20],
  notRefunded: palette.support.alert[30],
};

export const absenceColors: Record<AdminAbsenceType | UserAbsenceType, string> = {
  AnnualLeave: palette.support.info[30],
  SickLeave: palette.support.alert[30],
  SpecialLeave: palette.support.success[30],
  UnpaidLeave: palette.support.warning[30],
  FlextimeLeave: palette.additional.teal[20],
  MaternityLeave: palette.additional.magenta[30],
  OutOfOffice: palette.support.warning[30],
  DueToForceMajeureLeave: palette.greyscale[60],
  ChildCareLeave: palette.additional.yellow[30],
  PaidLeave: palette.additional.purple[30],
  CaregivingLeave: palette.additional.purple[30],
};

export const contractsWithAnnualLeave: ContractType[] = [ContractType.UOP];

export const incomeBonusDisplayOrder = [
  BonusType.EMPLOYEEREFERRAL,
  BonusType.APPRECIATION,
  BonusType.LANGUAGELEARNING,
  BonusType.TRAININGPACKAGE,
  BonusType.REMOTEWORKEQUIPMENT,
  BonusType.OTHER,
];

export const checkVersionIntervalMS = {
  focused: 30000,
  blurred: 180000,
};

export const maxReportingRange = {
  from: DateTime.local().minus({ years: 10 }).toJSDate(),
  to: DateTime.local().plus({ years: 10 }).toJSDate(),
};

export const emptyReportingRange = {
  from: maxReportingRange.from,
  to: maxReportingRange.from,
};

export enum InvoiceStatus {
  toBeChecked = 'toBeChecked',
  correct = 'correct',
  incorrect = 'incorrect',
}

export const invoiceStatusIcon: Record<InvoiceStatus, ReactElement> = {
  [InvoiceStatus.correct]: <CheckCircle color={palette.support.success[60]} />,
  [InvoiceStatus.incorrect]: <XCircle color={palette.support.warning[60]} />,
  [InvoiceStatus.toBeChecked]: <Question color={palette.support.info[60]} />,
};

export const shouldWarnAboutMissingEntriesDayOffset = 2;

export const holidayColor = palette.greyscale[50];
export const nonWorkingDayColor = palette.greyscale[50];
export const remoteWorkAccentColor = palette.support.info[30];

export const timeReportModalContainerOverflowChangePadding = 40;

export const flexTimeSettlementMonths = 4;

export const defaultHoursInWorkingDay = 8;
export const hoursInAbsenceDay = defaultHoursInWorkingDay;
export const defaultWorkingTime = 1;
export const noProjectId = 'noProjectId';
export const trDatePickerEnabledFutureYearsAmount = 1;

export const benefitCurrency = 'PLN';
export const defaultIncomeModalCurrency = 'PLN';

type LeaveEntryConfig<T> = Partial<Record<AbsenceType, T>>;

export const leaveCouldCoexistWithTypes: LeaveEntryConfig<AbsenceType[]> = {
  [AbsenceType.SICKLEAVE]: [AbsenceType.ANNUALLEAVE],
  [AbsenceType.ANNUALLEAVE]: [AbsenceType.SICKLEAVE],
};

export const leaveCouldCoexistWithOnCalls: LeaveEntryConfig<Partial<Record<ContractType, boolean>>> = {
  [AbsenceTypeInput.SPECIALLEAVE]: {
    [ContractType.UOP]: false,
  },
  [AbsenceTypeInput.MATERNITYLEAVE]: {
    [ContractType.UOP]: false,
  },
  [AbsenceTypeInput.UNPAIDLEAVE]: {
    [ContractType.UOP]: false,
  },
  [AbsenceTypeInput.ANNUALLEAVE]: {
    [ContractType.UOP]: false,
  },
  [AbsenceTypeInput.SICKLEAVE]: {
    [ContractType.UOP]: false,
  },
};

export const contractToApplicableHolidayTypes: Record<ContractType, CalendarDayType[]> = {
  [ContractType.B2B]: [CalendarDayType.HOLIDAY],
  [ContractType.MANAGEMENT]: [CalendarDayType.HOLIDAY],
  [ContractType.SUBCONTRACT]: [CalendarDayType.HOLIDAY],
  [ContractType.UOD]: [CalendarDayType.HOLIDAY],
  [ContractType.UZ]: [CalendarDayType.HOLIDAY],
  [ContractType.UOP]: [CalendarDayType.HOLIDAY, CalendarDayType.FREEDAYFOROTHERDAY],
};

export const absenceInputToType: Record<AbsenceTypeInput, AbsenceType> = {
  [AbsenceTypeInput.ANNUALLEAVE]: AbsenceType.ANNUALLEAVE,
  [AbsenceTypeInput.FLEXTIMELEAVE]: AbsenceType.FLEXTIMELEAVE,
  [AbsenceTypeInput.SICKLEAVE]: AbsenceType.SICKLEAVE,
  [AbsenceTypeInput.MATERNITYLEAVE]: AbsenceType.MATERNITYLEAVE,
  [AbsenceTypeInput.SPECIALLEAVE]: AbsenceType.SPECIALLEAVE,
  [AbsenceTypeInput.UNPAIDLEAVE]: AbsenceType.UNPAIDLEAVE,
  [AbsenceTypeInput.PAIDLEAVE]: AbsenceType.PAIDLEAVE,
  [AbsenceTypeInput.OUTOFOFFICE]: AbsenceType.OUTOFOFFICE,
  [AbsenceTypeInput.CHILDCARELEAVE]: AbsenceType.CHILDCARELEAVE,
  [AbsenceTypeInput.DUETOFORCEMAJEURELEAVE]: AbsenceType.DUETOFORCEMAJEURELEAVE,
  [AbsenceTypeInput.CAREGIVINGLEAVE]: AbsenceType.CAREGIVINGLEAVE,
};

export const absenceTypeToInput: Record<AbsenceType, AbsenceTypeInput> = {
  [AbsenceType.ANNUALLEAVE]: AbsenceTypeInput.ANNUALLEAVE,
  [AbsenceType.FLEXTIMELEAVE]: AbsenceTypeInput.FLEXTIMELEAVE,
  [AbsenceType.SICKLEAVE]: AbsenceTypeInput.SICKLEAVE,
  [AbsenceType.MATERNITYLEAVE]: AbsenceTypeInput.MATERNITYLEAVE,
  [AbsenceType.SPECIALLEAVE]: AbsenceTypeInput.SPECIALLEAVE,
  [AbsenceType.UNPAIDLEAVE]: AbsenceTypeInput.UNPAIDLEAVE,
  [AbsenceType.PAIDLEAVE]: AbsenceTypeInput.PAIDLEAVE,
  [AbsenceType.OUTOFOFFICE]: AbsenceTypeInput.OUTOFOFFICE,
  [AbsenceType.CHILDCARELEAVE]: AbsenceTypeInput.CHILDCARELEAVE,
  [AbsenceType.DUETOFORCEMAJEURELEAVE]: AbsenceTypeInput.DUETOFORCEMAJEURELEAVE,
  [AbsenceType.CAREGIVINGLEAVE]: AbsenceTypeInput.CAREGIVINGLEAVE,
};

export const absenceInputToReportedLeaveDaysKey: Record<Exclude<AbsenceTypeInput, 'FlextimeLeave'>, keyof Omit<ReportedLeaveDays, '__typename'>> = {
  [AbsenceTypeInput.ANNUALLEAVE]: 'annual',
  [AbsenceTypeInput.SICKLEAVE]: 'sickLeave',
  [AbsenceTypeInput.MATERNITYLEAVE]: 'maternity',
  [AbsenceTypeInput.SPECIALLEAVE]: 'special',
  [AbsenceTypeInput.UNPAIDLEAVE]: 'unpaid',
  [AbsenceTypeInput.PAIDLEAVE]: 'paid',
  [AbsenceTypeInput.OUTOFOFFICE]: 'outOfOffice',
  [AbsenceTypeInput.CHILDCARELEAVE]: 'childCare',
  [AbsenceTypeInput.DUETOFORCEMAJEURELEAVE]: 'dueToForceMajeure',
  [AbsenceTypeInput.CAREGIVINGLEAVE]: 'caregiving',
};

export const preferredLeaveOrder: AbsenceTypeInput[] = [
  AbsenceTypeInput.ANNUALLEAVE,
  AbsenceTypeInput.SPECIALLEAVE,
  AbsenceTypeInput.SICKLEAVE,
  AbsenceTypeInput.UNPAIDLEAVE,
  AbsenceTypeInput.FLEXTIMELEAVE,
  AbsenceTypeInput.MATERNITYLEAVE,
  AbsenceTypeInput.CAREGIVINGLEAVE,
  AbsenceTypeInput.DUETOFORCEMAJEURELEAVE,
  AbsenceTypeInput.OUTOFOFFICE,
  AbsenceTypeInput.CHILDCARELEAVE,
];

export const contractsWithRemoteWork: ContractType[] = [ContractType.UOP];
export const contractsWithWorkingTime: ContractType[] = [ContractType.UOP];

export const workedTimeColor = palette.additional.greenCyan[80];
export const flexTimeColor = palette.support.info[80];

export const fakeTimeBasedChangePrefix = 'fake-change';

export const endOfAnnualLeaveCarryOverPeriod = (): Date => {
  const today = DateTime.local();

  const thisYearsPeriodEnd = today.set({ day: 30, month: 9 });

  if (compareTimeUnits(today, thisYearsPeriodEnd, 'day') < 0) {
    return thisYearsPeriodEnd.toJSDate();
  }

  return thisYearsPeriodEnd.plus({ year: 1 }).toJSDate();
};

export const getLeaveDescriptions = (t: TranslatingFn): Record<AbsenceTypeInput, Nullable<string>> => ({
  AnnualLeave: t('holiday, max. 20 or 26 days, 100% paid'),
  FlextimeLeave: t('absence to be made up on another day'),
  UnpaidLeave: t('if you need more holiday, not paid'),
  SpecialLeave: t('birth of a child, wedding, funeral etc., 100% paid'),
  MaternityLeave: null,
  SickLeave: t('due to health reasons, 80% paid'),
  ChildCareLeave: t('for under 14 y/o child care, max. 16 hours or 2 days, 100% paid'),
  CaregivingLeave: t('for a family or household member\'s care, max. 5 days, not paid'),
  DueToForceMajeureLeave: t('for urgent matters, max. 16 hours or 2 days, 50% paid'),
  OutOfOffice: null,
  PaidLeave: t('for managers only, 100% paid'),
});

export const trainingPackageLimit = 4500;
export const trainingPackageTimeLimit = 24 * 60;
export const trainingPackageWorkTagName = 'Training package';
